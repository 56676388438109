.finaliza-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    max-width: 700px;
    position: relative;
}

.finaliza-title {
    position: absolute;
    top: 20px;
    left: 20px;
    margin: 0;
    color: #666;
    text-transform: uppercase;
    font-style: italic;
}

.finaliza-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}

.finaliza-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.finaliza-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8vh;
    align-items: center;
    justify-content: space-between;
}

.finaliza-form-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    margin-top: 2vh;
}

.finaliza-text {
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
}

.finaliza-input {
    height: 40px;
    border-radius: 5px;
    border: 3px solid #666;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    text-align: left;
    margin-top: 0;
}

.finaliza-input:focus {
    outline: none;
    border: 3px solid #ee1f26;
}

.finaliza-button {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 12px 0px;
    font-size: 18px;
    font-weight: 700;
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    background-color: #666;
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .finaliza-content {
        width: 90%;
        height: 100%;
        overflow-y: auto;
    }

    .finaliza-wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .finaliza-form-content {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .finaliza-input {
        width: 100%;
    }

    .finaliza-button {
        width: 100%;
    }
}