.menu-div {
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
}

.element-div {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
}

.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-top: 5vh;
}

.header-menu {
    font-size: 30px;
    color: #666;
    font-weight: 1000;
}

.content-menu {
    width: 80%;
    text-align: left;
    font-weight: 500;
}

.left-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rigth-content {
    width: 60%;
    height: 100%;
}

a {
    text-decoration: none;
    color: #ee1f26;
}

.mapa {
    width: 100%;
    height: 40vh;
    background-image: url("../../public/mapa.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.familias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin-top: 4vh;
    margin-bottom: 4vh;
    gap: 40px 0;
}

.productos {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.title-productos {
    font-size: 36px;
    font-weight: 1000;
    color: #666;
    text-transform: uppercase;
}

.grid-products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.categorias-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.categorias-section h2 {
    font-size: 36px;
    font-weight: 1000;
    color: #666;
    text-transform: uppercase;
}

.categorias {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.categorias a {
    text-decoration: none;
    color: #ee1f26;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px;
}

.categorias a:hover {
    background-color: #eee;
}

@media screen and (max-width: 768px) {
    .menu-div {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        margin-bottom: 50px;
    }

    .header-menu {
        margin: 0;
    }

    .content-menu {
        margin: 0;
    }

    .mapa {
        height: unset;
        aspect-ratio: 16/9;
        background-size: cover;
    }

    .contact a {
        width: 90%;
    }

    .contact {
        flex-direction: column;
        align-items: center;
        width: 90%;
        gap: 15px;
    }

    .left-content {
        align-items: center;
        gap: 10px;
    }

    .content-menu {
        text-align: center;
        width: 100%;
    }

    .grid-products {
        grid-template-columns: repeat(1, 1fr);
    }

    .title-productos {
        text-align: center;
    }

    .categorias {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
}