/* Estilos del menú principal */
nav {
    height: 12vh;
    width: 100%;
    position: fixed;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ee1f26;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: top 0.7s ease, width 0.7s ease;
    z-index: 999;
}

nav.fixed {
    width: 100vw;
    top: 0;
    border-radius: 0;
}

nav.scrolled {
    height: 7vh;
}

nav.scrolled .logo a {
    width: 40px;
    height: 40px;
}

nav.scrolled .navLinks ul li a {
    font-size: 1vw;
}

.hamburger {
    display: none;
    cursor: pointer;
    margin-right: 15px;
}

.movil-title {
    display: none;
}

.logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo a {
    width: 100%;
    height: 100%;
    display: block;
    background-image: url("/public/assets/logo.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.navAll {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navLinks {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navLinks ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
}

.navLinks ul li {
    list-style: none;
    position: relative;
    /* Añadimos posición relativa para alinear el submenú */
}

.navLinks ul li a {
    text-decoration: none;
    color: white;
    font-size: 1.2vw;
    font-weight: 600;
}

.navLinks ul li a:hover,
ul li a:focus {
    color: #666;
    outline: none;
}

/* Estilos para el submenú */
.submenu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ee1f26;
    display: none;
    /* Por defecto, el submenú estará oculto */
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    flex-direction: column;
}

/* Mostramos el submenú cuando el desplegable está abierto */
.menu-open .navLinks ul li:hover .submenu,
.menu-open .navLinks ul li:focus .submenu {
    display: flex;
    /* Mostramos el submenú como una columna */
}

.submenu li {
    margin: 5px 0;
    text-align: center;
}

.submenu a,
.submenu div {
    color: white;
    text-decoration: none;
    font-size: 1.1vw;
    font-weight: 600;
    cursor: pointer;
}

.submenu a:hover,
.submenu div:hover {
    color: #666;
}

/* Resto de estilos */

.joinButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.joinButton a {
    text-decoration: none;
    padding: 15px 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--logo);
    font-size: 1vw;
    font-weight: 400;
    color: white;
    transition: background-color 0.5s ease;
}

.joinButton a:hover,
.joinButton a:focus {
    background-color: white;
    color: var(--logo);
    outline: none;
}

.shopping-cart-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.shopping-cart-counter {
    position: absolute;
    bottom: 80%;
    left: 80%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border-radius: 50%;
    font-size: 0.8vw;

}

.cart {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.cart-bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.cart-container {
    z-index: 1000;
    width: 40%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    position: relative;
}

.cart-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #ee1f26;
    color: white;
}

.cart-header h3 {
    font-size: 1.5vw;
    font-weight: 600;
    color: white;
}

.cart-header button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5vw;
    cursor: pointer;
}

.cart-items {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
    background-color: white;
}

.cart-items::-webkit-scrollbar {
    width: 10px;
}

.cart-items::-webkit-scrollbar-thumb {
    background-color: #ee1f26;
    border-radius: 10px;
}

.cart-items::-webkit-scrollbar-track {
    background-color: white;
}

.cart-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.cart-item img {
    width: 20%;
    height: 100%;
    object-fit: contain;
}

.cart-item div {
    width: 60%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.cart-item h4 {
    font-size: 1.2vw;
    font-weight: 600;
    margin: 0;
}

.cart-item p {
    font-size: 1.2vw;
    font-weight: 700;
    margin: 0;
}

.cart-footer {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #ee1f26;
    color: white;
}

.cart-footer h4 {
    font-size: 1.5vw;
    font-weight: 600;
}

.cart-footer button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5vw;
    cursor: pointer;
}



@media only screen and (max-width: 1100px) {
    .logo {
        width: 100%;
    }

    .navLinks ul li a {
        color: black;
        font-size: 18px;
    }

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5vw;
        width: 90%;
    }

    .hamburger {
        display: block;
    }

    .navAll {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        z-index: 9999;
        /* pointer-events: none; */
        transition: right 0.3s ease, opacity 0.3s ease;
        margin: 0;
    }

    .menu-open .navAll {
        right: 0;
        opacity: 1;
        pointer-events: auto;
    }

    .movil-title {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--logo);
    }

    .navLinks {
        width: 80%;
        height: unset;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .navLinks ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0;
    }

    .navLinks ul li a {
        color: var(--text);
    }

    .navLinks ul li div {
        color: var(--text);
        font-weight: 600;
        font-size: 18px;
    }

    .menu-open .navLinks ul li {
        margin-bottom: 10px;
    }

    .submenu {
        position: static;
        /* Cambiamos la posición del submenú en móvil */
        width: 100%;
        display: none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
    }

    .menu-open .submenu {
        display: flex;
        flex-direction: column;
    }

    .submenu a {
        font-size: 18px;
        /* Tamaño de fuente para enlaces del submenú en móvil */
    }

    .joinButton a {
        font-size: 18px;
    }

    .shopping-cart-container {
        position: fixed;
        z-index: 996;
        background-color: red;
        padding: 20px;
        border-radius: 50%;
        right: 0;
        top: 75vh;
    }

    .shopping-cart-container img {
        width: 35px;
        height: 35px;
    }

    .shopping-cart-counter {
        left: 60%;
        bottom: 60%;
        font-size: 14px;
    }

    .cart-header,
    .cart-items,
    .cart-footer {
        box-sizing: border-box;
    }

    .cart-container {
        width: 100%;
    }

    .cart-header h3 {
        font-size: 24px;
    }

    .cart-header button {
        font-size: 24px;
    }

    .cart-items {
        padding: 10px;
    }

    .cart-item img {
        width: 30%;
    }

    .cart-item div {
        width: 50%;
    }

    .cart-item h4 {
        font-size: 20px;
    }

    .cart-item p {
        font-size: 20px;
    }

    .cart-footer h4 {
        font-size: 24px;
    }

    .cart-footer button {
        font-size: 24px;
    }

}

.no-scroll {
    overflow: hidden;
}