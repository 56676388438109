.card-box {
    border-radius: 10px;
    border: 5px solid #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.foto-card {
    aspect-ratio: 16/9;
    width: 100%;
    height: 57%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0px 0px;
}

.inside-box {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title-card {
    width: 85%;
    text-align: center;
    margin-bottom: 0.5em;
}

.precio-card {
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: black;
}

.cta-card {
    background-color: #666;
    color: white;
    border: 0;
    width: 100%;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.card-box:hover {
    border-color: #ee1f26;
}

.card-box:hover .cta-card {
    background-color: #ee1f26;
}