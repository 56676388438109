.categoria-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
}

.categoria-page-titulo {
    font-size: 36px;
    font-weight: 1000;
    font-style: italic;
    color: #666;
    text-transform: uppercase;
    position: relative;
}

.categoria-page-titulo::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 90vw;
    height: 8px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #666, transparent);
}

.categoria-page-content {
    display: flex;
    flex-direction: column;
    gap: 12vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.categoria-page-logo {
    width: 100%;
    object-fit: contain;
}

.categoria-page-descripcion {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    margin: 0;
}

.categoria-page-descripcion p {
    margin-top: 0;
}

.subcategorias-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.subcategoria {
    background-color: #666;
    color: white;
    padding: 10px 20px;
    border-radius: 12px;
}

@media screen and (max-width: 768px) {

    .categoria-page-container {
        align-items: center;
    }

    .categoria-page-titulo {
        width: 90%;
    }

    .categoria-page-titulo::after {
        width: 100%;
    }

    .categoria-page-content {
        gap: 4vh;
    }

    .categoria-page-descripcion {
        text-align: center;
    }
}