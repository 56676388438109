.terminos-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 12vh;
    margin-bottom: 50px;
}

.terminos-container h1,
h2,
h3 {
    color: #ee1f26;
}