.producto-page {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 15vh;
}

.multimedia-producto-page {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: sticky;
    top: 15vh;
    /* Ajusta la posición superior según sea necesario */
}

.img-producto-page {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
}

.title-section-producto {
    font-size: 36px;
    font-weight: 700;
    color: #666;
    margin-top: 10px;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.descripcion-valoraciones {
    font-style: italic;
    margin: 0;
}

.estrellas {
    font-size: 54px;
    cursor: pointer;
}

.estrella-llena {
    color: gold;
}

.estrella-vacia {
    color: #ccc;
    /* Color de las estrellas vacías */
}

.info-producto-page {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.title-producto-page {
    font-size: 36px;
    font-weight: 1000;
    color: black;
    text-transform: uppercase;
    margin-top: 0;
}

.shipping-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.shipping-title {
    background-color: #ee1f26;
    width: 48%;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    margin-bottom: 10px;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
}

.active {
    background-color: #666;
}

.editar-cp {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.editar-cp input {
    width: 15%;
    border-radius: 10px;
    border: 3px solid #ee1f26;
    outline: none;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.editar-cp button {
    background-color: #666;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 8px 15px;
    cursor: pointer;
}

.editar-cp button:hover {
    background-color: #ee1f26;
}

.cp-envio {
    font-size: 18px;
    font-weight: 500;
    color: #ee1f26;
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.cp-envio span {
    font-weight: 1000;
    cursor: pointer;
}

.precio-producto-page {
    font-size: 28px;
    font-weight: 1000;
    color: #666;
    margin-top: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.precio-producto-page span {
    font-size: 18px;
    font-weight: 700;
}

.descripcion-producto-page {
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-top: 0;
}

.cta-section-producto-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5vh;
}

.cta-producto-page {

    padding: 15px 20px;
    background-color: #666;
    border-radius: 10px;
    color: white;
    border: none;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5vh;
    cursor: pointer;
    width: 49%;
}

.cta-carrito {
    background-color: #ee1f26;
}

.productos-relacionados-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    gap: 2vh;
}

@media screen and (max-width: 768px) {
    .producto-page {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        gap: 15px;
    }

    .title-section-producto {
        display: none;
    }

    .oneline-multimedia-file {
        display: none;
    }

    .multimedia-producto-page {
        width: 100%;
        position: unset;
        top: unset;
    }

    .info-producto-page {
        width: 100%;
    }

    .title-producto-page {
        font-size: 36px;
    }

    .precio-producto-page {
        font-size: 30px;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    .descripcion-producto-page {
        font-size: 16px;
    }

    .cta-section-producto-page {
        flex-direction: column;
        gap: 10px;
    }

    .cta-link {
        width: 100%;
    }

    .cta-producto-page {
        width: 100%;
        margin-bottom: 0;
    }

    .shipping-container {
        flex-direction: column;
    }

    .shipping-title {
        width: 100%;
    }

    .cp-container {
        width: 100%;
    }

    .editar-cp {
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }

    .editar-cp input {
        width: 100%;
        height: 7vh;
        padding: 0;
    }

    .editar-cp button {
        width: 100%;
        height: 7vh;
    }
}