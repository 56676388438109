.sobre-nosotros {
    margin-top: 15vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.sobre-nosotros h2 {
    text-align: left;
    width: 100%;
    font-size: 36px;
    font-weight: 1000;
    font-style: italic;
    color: #666;
    text-transform: uppercase;
    position: relative;
}

.sobre-nosotros p {
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-align: left;
    text-transform: uppercase;
    margin-top: 0;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .sobre-nosotros p {
        text-align: center;
    }
}