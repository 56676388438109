.exitosa-container {
    width: 100%;
    padding: 7vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 12vh;
}

.exitosa-title {
    font-size: 56px;
    font-weight: 1000;
    font-style: italic;
    color: #666;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    margin: 10px;
}

.exitosa-text {
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    width: 100%;
}

.exitosa-img {
    height: 40%;
    max-height: 400px;
    width: auto;
    object-fit: contain;
}

.exitosa-button {
    padding: 15px 35px;
    background-color: #666;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}